
import { Component } from 'vue-property-decorator';
import GradesToolbar from './GradesToolbar.vue';

  @Component({
    components: {
      GradesToolbar
    }
  })
export default class GradesBottomToolbar extends GradesToolbar {
}
