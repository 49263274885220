var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isEmbedded
    ? _c(
        "v-toolbar",
        [
          _c(
            "v-row",
            {
              staticClass: "text-center justify-space-between",
              attrs: { elevation: "1", outlined: "" },
            },
            [
              _c("pb-user-mode-selector"),
              _vm.$currentUser.isTeacher
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "pb-btn",
                        {
                          attrs: {
                            color: "primary",
                            label:
                              _vm.$t("addLabel") +
                              " " +
                              _vm.$t("classworkLabel"),
                            icon: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.addClasswork.apply(null, arguments)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("fal fa-plus")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$currentUser.isTeacher
                ? _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: onMenu }) {
                                  return [
                                    _c(
                                      "pb-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            color: "primary",
                                            label: _vm.$t("actionsLabel"),
                                            icon: "",
                                          },
                                        },
                                        { ...onMenu }
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(
                                            "fal fa-ellipsis-stroke-vertical"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1297348514
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.dialog.scoreWeight = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { text: "", color: "primary" } },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("fal fa-chart-pie"),
                                      ]),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("scoreWeightLabel"))
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        bottom: "",
                        "offset-y": "",
                        "close-on-click": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: onMenu }) {
                              return [
                                _c(
                                  "pb-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "primary",
                                        label: _vm.$t("sendToLabel"),
                                        icon: "",
                                      },
                                    },
                                    { ...onMenu }
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("fal fa-arrow-up-from-square"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3435299453
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          !_vm.$currentUser.isAdmin
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showPrintGrades(
                                        _vm.$t("csvLabel").toString()
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: "primary", text: "" } },
                                    [
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("gradesSummaryLabel")) +
                                            " (" +
                                            _vm._s(_vm.$t("csvLabel")) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.$currentUser.isAdmin
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showPrintGrades(
                                        _vm.$t("pdfLabel").toString()
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { color: "primary", text: "" } },
                                    [
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("gradesSummaryLabel")) +
                                            " (" +
                                            _vm._s(_vm.$t("pdfLabel")) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.dialog.performanceReport = true
                                },
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { color: "primary", text: "" } },
                                [
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("performanceReportsLabel")
                                      ) +
                                        " (" +
                                        _vm._s(_vm.$t("pdfLabel")) +
                                        ")"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }